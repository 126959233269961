@use './variables' as v;
@import './adaptive';
#manager{
    scroll-margin-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    .cell{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        background: v.$white;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        &.general-manager{
            gap: 32px;
            .subtitle{
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                color: v.$main_yellow;
            }
            .info{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 32px;
                .text{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 16px;
                    width: 100%;
                    .title{
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;                        
                        color: v.$black;
                    }
                    .description{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: v.$dark_grey;
                    }
                }
                .illustration{
                    max-width: 240px;
                    i{
                        content: url('../utilities/images/general-manager.png');
                    }
                }
            }
        }
        &.experience{
            gap: 24px;
            .subtitle{
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                color: #969696;
            }
            .tabs{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 16px;
                .tab{
                    padding-left: 16px;
                    border-left: solid 2px v.$main_yellow;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2px;
                    font-size: 20px;
                    line-height: 32px;
                    color: v.$dark_grey;
                    .title{
                        font-weight: 600;
                    }
                    .description{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            .description{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: v.$dark_grey;
            }
        }
        &.team-and-projects{
            gap: 24px;
            .subtitle{
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                color: #969696;
            }
            .items{
                display: flex;
                flex-direction: column;
                padding: 0px;
                gap: 24px;
                width: 100%;
                .item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px;
                    gap: 8px;
                    .number{
                        font-weight: 700;
                        font-size: 48px;
                        line-height: 56px;
                        color: #607DE6;
                        border-bottom: 2px solid rgba(96, 125, 229, 0.4);                      
                    }
                    .text{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #141414;
                        text-align: center;
                    }
                }
            }
        }
    }
    .partners{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        .title{
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            color: v.$main_black;
        }
        .wrapper{
            width: 100%;
        }
        .scroller{
            display: block;
            width: 100%;
            .cards{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 24px;
                .card{
                    *{
                        text-decoration: none;
                        color: v.$black;
                    }
                    width: 100%;
                    box-sizing: border-box;
                    padding: 48px;
                    // height: 130px;
                    background: v.$white;
                    border: 1px solid #E7E7E7;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    cursor: pointer;
                    .text{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 4px;
                        .title{
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 32px;
                        }
                        .description{
                            text-decoration: none;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    &.rag-mirsot i{
                        content: url('../utilities/images/rag-mirsot-logo.png');
                    } 
                    &.ra-group i{
                        content: url('../utilities/images/ra-group-logo.png');
                    }      
    
                }
            }
        }
    }
    @include _lg{
        .cell{
            padding: 48px;
            &.general-manager{
                .info{
                    flex-direction: row;
                    align-items: flex-end;
                    .text{
                        width: 432px;
                        .title{
                            font-size: 36px;
                            line-height: 48px;                        
                        }
                        .description{
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                    .illustration{
                        width: 240px;
                        i{
                            content: url('../utilities/images/general-manager.png');
                        }
                    }
                }
            }
            &.experience{
                .subtitle{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 32px;
                    color: #969696;
                }
                .tabs{
                    .tab{
                        .description{
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                }
                .description{
                    font-size: 20px;
                    line-height: 32px;
                }
            }
            &.team-and-projects{
                gap: 24px;
                .items{
                    flex-direction: row;
                    align-items: flex-end;
                    gap: 16px;
                    .item{
                        align-items: flex-start;
                        width: calc(50% - 8px);
                        .text{
                            font-size: 20px;
                            line-height: 32px;
                            text-align: initial;
                        }
                    }
                }
            }
        }
        .partners{
            width: 800px;
            .wrapper{
                position: relative;
                width: 100%;
                height: 100%;
                .scroller{
                    left: calc((800px - 100vw) / 2);
                    // position: absolute;
                    // width: 100vw;
                    height: 100%;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    overflow: visible;
                    overflow-y: hidden;
                    overflow-x: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    .cards{
                        // width: auto;
                        // margin-left: calc((100vw - 800px) /2);
                        // margin-right: calc((100vw - 800px) / 2);
                        flex-direction: row;
                        .card{
                            width: 388px;
                            min-width: 388px;
                            align-self: stretch;
                            padding: 48px;
                            .content{                        
                                .description{
                                    font-size: 20px;
                                    line-height: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}