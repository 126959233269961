@use './variables' as v;
@import './adaptive';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;

    white-space: pre-line;
}
body{
    margin: 0;
    padding: 0;
    background: v.$light_grey;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    #root{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        
        main{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding: 120px 16px 86px;

            & > .content{
                padding-top: 56px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 48px;
                width: 100%;
            }
            @include _lg{
                padding: 200px 16px;
                & > .content{
                    position: relative;
                    width: 800px;
                    padding-top: 124px;
                    gap: 64px;
                }
            }
        }
    }
}
a{
    text-decoration: none;
}