$white: #ffffff;
$darker_white: #EBEBEB;
$main_black: #141414;
$black: #282828;
$mid_grey: #ECEDEE;
$light_grey: #F3F4F5;
$grey: #E5E7E9;
$dark_grey: #323232;

$main_yellow: #EBB249;
$golden: #FFC65E;